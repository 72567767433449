import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import CloseIcon from "../../assets/icons/close.svg";

import styles from "./step-navigation.module.scss";

const StepNavigationItem = (props) => {
  const { currentStep, index, goToStep, title } = props;

  return (
    <div
      className={classNames(styles.stepButton, {
        [styles.stepButtonActive]: currentStep === index + 1,
      })}
      role="button"
      onClick={() => {
        goToStep(index + 1);
      }}
    >
      {title}
    </div>
  );
};

StepNavigationItem.defaultProps = {
  goToStep: () => { },
  title: "",
};

const StepNavigation = (props) => {
  const {
    steps,
    currentStep,
    goToStep,
    nextType,
    forForm,
    nextTitle,
    finalTitle,
    customNextButton,
    locked,
    children,
    canClose,
  } = props;

  const history = useHistory();

  const handleBack = () => {
    if (canClose) {
      history.goBack();
    } else {
      if (
        window.confirm(
          "You may have unsaved changes. Do you want to leave anyway?",
        )
      ) {
        history.goBack();
      }
    }
  };

  let buttonTitle = steps.length === currentStep ? finalTitle : nextTitle;

  if (locked) {
    buttonTitle = steps[currentStep - 1].lockedTitle;
  }
  let nextButton = null;
  if (!customNextButton) {
    nextButton = (
      <button
        className={classNames(styles.nextButton, {
          [styles.locked]: locked,
        })}
        form={forForm}
        type={nextType}
      >
        {buttonTitle}
      </button>
    );
  }

  if (currentStep === 6) {
    nextButton = (
      <button
        type="button"
        className={classNames(styles.nextButton, {
          [styles.locked]: locked,
        })}
        onClick={() => handleBack()}
      >
        {buttonTitle}
      </button>
    );
  }

  return (
    <nav className={styles.container}>
      <div className={styles.left}>
        <CloseIcon className={styles.iconClose} onClick={() => handleBack()} />
      </div>
      <div className={styles.center}>
        {steps.map((item, index) => (
          <StepNavigationItem
            currentStep={currentStep}
            goToStep={goToStep}
            index={index}
            title={item.title}
            key={`step-${index}`}
          />
        ))}
        {children}
        {nextButton}
      </div>
    </nav>
  );
};

StepNavigation.defaultProps = {
  steps: [],
  children: [],
  goToStep: () => { },
  customNextButton: false,
  nextType: "submit",
  nextTitle: "Next",
  finalTitle: "Save",
  forForm: "",
};

export default StepNavigation;
