import moment from "moment";

const defaultAssignment = (section, type = "normal", isHBP = false) => {
  const dateNow = moment.utc().local().format("YYYY-MM-DD HH:mm");

  const PLEmail = "Help@PeerLearningVenture.com";
  const HBPEmail = "techhelp@hbsp.harvard.edu";

  let data = {
    body: "<p></p>",
    body_plaintext: "",
    title: "",
    type: type,
    description:
      '<p><strong>Stage One - Submit</strong></p><p>For this stage you will select "Create a Submission" to your right.</p> <p><strong>Stage Two - Review</strong></p> <p>In this Stage you will be asked to review work by classmates created in Stage One according to various quantitative and qualitative criteria.</p> <p><strong>Stage Three - Score Reviews</strong></p> <p>In this stage you will be asked to indicate the helpfulness of peers\' feedback.</p> <p><strong>Most Important to Note! </strong></p> <p>Peer Exchange uses an algorithm that moves all student work to the next Stage simultaneously. At each deadline, your work is distributed immediately to your classmates. PLEASE BE SURE TO MEET ALL DEADLINES.</p> <p><strong>Other Important Information:</strong></p> <p>1. Each Stage has pop-up instructions to guide you; please read them the first time participating in each Stage.</p> <p>2. Your evaluations and feedback are intended to be anonymous to your classmates. Please delete identifying information in your Submission and your Reviews.</p> <p>3. Your instructors have access to all your work.</p> <p>4. You must hit Save for your work to be accepted. You will know that your work is Saved when the banner at the top of page turns green and the text indicates Saved work. The other way to know that your work is SAVED is when the corresponding TO-DO turns green.</p> <p>5. If you upload attachment(s), use a format that all classmates can access, such as Word, PDF, Excel, PPT.</p> <p>6. If you choose to use the large text box: Copy/paste from Word may not retain the same formatting. It\'s best to copy/paste using your computer\'s text editor, or to copy/paste from google docs.</p> <p>For technical help please email <a href="mailto:Help@PeerLearningVenture.com" target="_self">Help@PeerLearningVenture.com</a></p>',
    description_plaintext:
      "Stage One - Submit For this stage you will select \"Create a Submission\" to your right.  Stage Two - Review In this Stage you will be asked to review work by classmates created in Stage One according to various quantitative and qualitative criteria.  Stage Three - Score Reviews In this stage you will be asked to indicate the helpfulness of peers' feedback.  Most Important to Note!  Peer Exchange uses an algorithm that moves all student work to the next Stage simultaneously. At each deadline, your work is distributed immediately to your classmates. PLEASE BE SURE TO MEET ALL DEADLINES.  Other Important Information: 1. Each Stage has pop-up instructions to guide you; please read them the first time participating in each Stage.  2. Your evaluations and feedback are intended to be anonymous to your classmates. Please delete identifying information in your Submission and your Reviews.  3. Your instructors have access to all your work.  4. You must hit Save for your work to be accepted. You will know that your work is Saved when the banner at the top of page turns green and the text indicates Saved work. The other way to know that your work is SAVED is when the corresponding TO-DO turns green.  5. If you upload attachment(s), use a format that all classmates can access, such as Word, PDF, Excel, PPT.  6. If you choose to use the large text box: Copy/paste from Word may not retain the same formatting. It's best to copy/paste using your computer's text editor, or to copy/paste from google docs.  For technical help please email Help@PeerLearningVenture.com",
    publish_date: dateNow,
    due_date: moment().add(2, "days").utc().local().format("YYYY-MM-DD HH:mm"),
    review_due_date: moment()
      .add(4, "days")
      .utc()
      .local()
      .format("YYYY-MM-DD HH:mm"),
    review_feedback_due_date: moment()
      .add(6, "days")
      .utc()
      .local()
      .format("YYYY-MM-DD HH:mm"),
    oral_due_date: moment()
      .add(8, "days")
      .utc()
      .local()
      .format("YYYY-MM-DD HH:mm"),
    submission_rubric: [
      {
        id: "3820e7b3-22dc-434e-a9e6-e5d206eb31af",
        type: "description",
        label:
          "You are now in Stage 2 where you will write Reviews based on classmates' Submissions. Next in Stage 3, others will evaluate the Reviews that you write based on: 1. Consistency between the scores you assigned and your written comments; 2. Specific references to the submission's strengths; and 3. Specific suggestions for improvement.  Authors of the original Stage 1 Submissions will not evaluate the quality of your Reviews - a different group of students will. Given there is no incentive for reviewers to please authors, this approach helps to promote honest and constructive feedback. You must SAVE your work for each Review. Look for the green banner pop-up to know that your work is saved.",
        answer: null,
      },
    ],
    review_rubric: [
      {
        id: "cb719091-6e4d-4ccd-b791-54d83e5991eb",
        type: "description",
        label:
          "As previously noted in the Stage 2 instructions, here you are asked to evaluate Reviews from Stage 2. Reviews are grouped for your convenience. All Reviews at the top of your TO-DO list refer to one Submission, while all Reviews at the bottom of your TO-DO list refer to a second Submission.  In order to evaluate the quality of Reviews you will need to read the relevant Submission, found in the drop-down menu in the upper left-hand corner of the screen.",
        answer: null,
      },
      {
        id: "56b04118-f6ac-406f-8a35-e8505a1d899d",
        type: "description",
        label:
          "Important Reminder! The Reviews assigned to you do NOT refer to your Submission.",
        answer: null,
      },
      {
        id: "294b18e5-e987-42b0-ae82-08714c1eaff5",
        type: "5-stars",
        label:
          "The numeric scores in this Review are consistent with the written comments.",
        answer: null,
      },
      {
        id: "032620c6-a626-4f2e-b1a2-985928fb1811",
        type: "5-stars",
        label:
          "The Review highlights specific examples of what is effective about the Submission.",
        answer: null,
      },
      {
        id: "649f31ec-fe5b-45a3-b22f-2ea613ddc26b",
        type: "5-stars",
        label:
          "The Review offers specific suggestion(s) that would help to improve the Submission.",
        answer: null,
      },
    ],
    section: section,
    skip_feedback_state: false,
    show_review_qualitative: true,
    allow_late_work: false,
    late_work_ai: true,
    late_work_due_date: moment()
      .add(6, "days")
      .utc()
      .local()
      .format("YYYY-MM-DD HH:mm"),
  };

  // If it's an HBP section, replace the email addresses
  if (isHBP) {
    data.description = data.description.replace(PLEmail, HBPEmail);
    data.description_plaintext = data.description_plaintext.replace(
      PLEmail,
      HBPEmail,
    );
  }

  return data;
};

export default defaultAssignment;
