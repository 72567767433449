import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import { useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";
import isEmpty from "lodash/isEmpty";

import Step1 from "./assignment-edit-step-1";
import Step2 from "./assignment-edit-step-2";
import Step3 from "./assignment-edit-step-3";
import Step4 from "./assignment-edit-step-4";
import Step5 from "./assignment-edit-step-5";
import Step6 from "./assignment-edit-step-6";

import Loading from "../../../components/loading/loading";
import StepNavigation from "../../../components/step-navigation/step-navigation";
import {
  getAssignment,
  createAssignment,
  updateAssignment,
} from "../../../queries/admin-queries";
import { getFormHelp, formHelpQuerySettings } from "../../../queries/help";
import defaultAssignment from "../../../common/assignment-defaults";
import styles from "./assignment-edit.module.scss";

const AssignmentEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isEditMode = location.pathname.endsWith("/edit");
  const dateNow = moment.utc().local().format("YYYY-MM-DD HH:mm");
  const [currentStep, setCurrentStep] = useState(1);
  const [canClose, setCanClose] = useState(true);

  // GET ASSIGNMENT
  const {
    isLoading,
    isSuccess,
    data: assignmentDetail,
  } = useQuery(["admin-assignment", id], () => getAssignment(id), {
    enabled: isEditMode,
  });

  const help = useQuery(
    ["form-help", "assignment-edit"],
    () => getFormHelp("assignment-edit"),
    formHelpQuerySettings,
  );

  // UPDATE ASSIGNMENT
  const { mutate: mutateUpdateAssigment } = useMutation(
    ["admin-assignment", id],
    (values) => updateAssignment(id, values),
  );

  const onUpdateSubmit = (formData) => {
    mutateUpdateAssigment(formData, {
      onSuccess: (data, values) => {
        console.log("Updated assignment!");
        console.dir(formData);
        console.dir(data);
        console.dir(values);

        // Clear the assignment cache for faculty
        queryClient.invalidateQueries(["admin-assignment", id]);
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
      },
    });
  };

  // CREATE ASSIGNMENT
  const { mutate: mutateCreateAssigment } = useMutation(
    "admin-assignment",
    (values) => createAssignment(values),
  );

  const onCreateSubmit = (formData) => {
    mutateCreateAssigment(formData, {
      onSuccess: (data, values) => {
        console.log("Created assignment!");
        console.dir(data);
        console.dir(values);
        let url = `/assignments/${data.id}/edit`;
        console.log(`URL: ${url}`);
        history.push(url);
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
      },
    });
  };

  let editOrCreateAssignment = onUpdateSubmit;

  if (!isEditMode) {
    editOrCreateAssignment = onCreateSubmit;
  }

  const [assignmentDraft, setAssignmentDraft] = useState(defaultAssignment(id));

  useEffect(() => {
    if (isEditMode) {
      setAssignmentDraft({ ...assignmentDetail });
    }
  }, [isSuccess, assignmentDetail]);

  const [direction, setDirection] = useState("forward");
  const transition = useTransition(currentStep, (currentStep) => currentStep, {
    initial: null,
    from: {
      opacity: 0,
      transform: `translate3d(${direction === "forward" ? "100%" : "-100%"},0, 0)`,
    },
    enter: {
      opacity: 1,
      transform: `translate3d(${direction === "forward" ? "0%" : "0%"}, 0, 0)`,
    },
    leave: {
      opacity: 0,
      transform: `translate3d(${direction === "forward" ? "-100%" : "100%"}, 0, 0)`,
    },
  });

  const goToStep = (step) => {
    setDirection("back");
    if (step < currentStep) {
      setCurrentStep(step);
    }
  };

  const removeStep = (stepId, steps) => {
    return steps.filter((step) => step.id !== stepId);
  };

  // All steps
  let steps = [
    { id: 1, title: "Welcome Page" },
    { id: 2, title: "Assignment" },
    { id: 3, title: "Platform Setup" },
    { id: 4, title: "Review Rubric" },
    { id: 5, title: "Score Review Rubric" },
    { id: 6, title: "Timed Oral Assignment" },
  ];

  // Determine if we are skipping step 5
  if (isEditMode && assignmentDraft.skip_feedback_state) {
    steps = removeStep(5, steps);
  }

  // Determine if we are skipping step 6
  if (isEditMode && !assignmentDraft.has_oral_assignment) {
    steps = removeStep(6, steps);
  }

  const getLastStep = () => {
    if (assignmentDraft.has_oral_assignment) {
      return 6;
    }
    if (!assignmentDraft.skip_feedback_state) {
      return 5;
    }
    return 4;
  };

  const getNextStep = (currentStepNumber) => {
    setDirection("forward");
    switch (currentStep) {
      case 4:
        // If scoring isn't required, skip to step 6 (if it exists) or end
        if (assignmentDraft.skip_feedback_state) {
          return assignmentDraft.has_oral_assignment ? 6 : getLastStep();
        }
        return 5;
      case 5:
        // If there's no oral component, we're done
        return assignmentDraft.has_oral_assignment ? 6 : getLastStep();
      default:
        // For steps 1-3, just go to next step
        return currentStepNumber + 1;
    }
  };

  const goToNextStep = () => {
    setCurrentStep(getNextStep(currentStep));
  };

  const updateAndSave = (assignmentData) => {
    // Create merged assignment data preserving all existing fields
    const updatedAssignment = {
      ...assignmentDraft, // Keep all existing fields
      ...assignmentData, // Override with any new fields
    };

    // set the assignment draft to keep the old logic working
    setAssignmentDraft(updatedAssignment);

    // hit the API with a patch to update it for real
    editOrCreateAssignment(updatedAssignment);

    // Allow the page to be closed
    setCanClose(true);
  };

  if (isEditMode && isEmpty(assignmentDraft)) {
    return <div>Loading</div>;
  }

  if (isLoading) {
    return <Loading message="Loading asssignment..." />;
  }

  const lastStep = getLastStep();

  return (
    <div className={styles.container}>
      <StepNavigation
        currentStep={currentStep}
        goToStep={goToStep}
        steps={steps}
        nextType="submit"
        forForm="step-form"
        nextTitle="Save &amp; Continue"
        finalTitle="Save"
        canClose={canClose}
      />
      {transition.map(({ item, props, key }) => (
        <animated.div className={styles.stepContainer} style={props} key={key}>
          {currentStep === 1 && (
            <Step1
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              assignmentDraft={assignmentDraft}
              setAssignmentDraft={updateAndSave}
              setCanClose={setCanClose}
              help={help}
            />
          )}
          {currentStep === 2 && (
            <Step2
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              assignmentDraft={assignmentDraft}
              setAssignmentDraft={updateAndSave}
              setCanClose={setCanClose}
              help={help}
            />
          )}
          {currentStep === 3 && (
            <Step3
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              assignmentDraft={assignmentDraft}
              setAssignmentDraft={updateAndSave}
              setCanClose={setCanClose}
              help={help}
            />
          )}
          {currentStep === 4 && (
            <Step4
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              assignmentDraft={assignmentDraft}
              setAssignmentDraft={updateAndSave}
              updateAssignment={updateAssignment}
              setCanClose={setCanClose}
              isLastStep={lastStep === 4}
              help={help}
            />
          )}
          {currentStep === 5 && (
            <Step5
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              assignmentDraft={assignmentDraft}
              setAssignmentDraft={updateAndSave}
              updateAssignment={updateAssignment}
              setCanClose={setCanClose}
              isLastStep={lastStep === 5}
              help={help}
            />
          )}
          {currentStep === 6 && (
            <Step6
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              assignmentDraft={assignmentDraft}
              setAssignmentDraft={updateAndSave}
              updateAssignment={updateAssignment}
              setCanClose={setCanClose}
              isLastStep={lastStep === 6}
              help={help}
            />
          )}
        </animated.div>
      ))}
    </div>
  );
};

export default AssignmentEdit;
