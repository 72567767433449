import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import HelpLabel from "../help-label";
import styles from "./date-input.module.scss";

const DateInput = React.memo((props) => {
  const {
    defaultDate,
    name,
    label,
    placeholder,
    minDate,
    maxDate,
    maxTime,
    minTime,
    showTimeSelect,
    dateFormat,
    error,
    register,
    unregister,
    setValue,
    onChange,
    help,
  } = props;
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    register(name, { required: true });
    setValue(name, startDate, { shouldDirty: true });
    return () => unregister(name);
  }, [name, register, unregister, startDate, setValue]);

  useEffect(() => {
    if (defaultDate) {
      setStartDate(moment(defaultDate).toDate());
    }
  }, [defaultDate]);

  const handleChange = (date) => {
    if (date) {
      setStartDate(date);
      setValue(name, moment(date.toISOString()));
    }
  };

  return (
    <React.Fragment>
      <HelpLabel label={label} name={name} help={help} />
      <DatePicker
        {...register(name, { required: true })}
        className={classnames(styles.input, {
          [styles.inputError]: error,
        })}
        name={name}
        dropdownMode="scroll"
        selected={startDate}
        onChange={handleChange}
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        todayButton="Today"
        dateFormat={dateFormat}
        timeFormat="HH:mm"
        placeholderText={placeholder}
        popperModifiers={[
          { name: "preventOverflow", options: { escapeWithReference: false } },
        ]}
      />
      <div className={styles.errorWrapper}>
        {error && (
          <React.Fragment>
            {error.types && (
              <span className={styles.error}>{error.types.message}</span>
            )}
            {error.message && (
              <span className={styles.error}>{error.message}</span>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});

DateInput.propTypes = {
  defaultDate: PropTypes.string,
  name: PropTypes.string.isRequired,
  maxDate: PropTypes.shape({}),
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.string,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  error: PropTypes.shape({}),
};

DateInput.defaultProps = {
  defaultDate: "",
  maxDate: null,
  showTimeSelect: true,
  dateFormat: "MM-dd-yyyy HH:mm",
  label: "",
  placeholder: "",
  minDate: null,
  minTime: null,
  maxTime: null,
  error: undefined,
};

export default DateInput;
